import { parse, stringify } from 'qs';
import { useCallback } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

export function useSearchParams<T = Record<string, string>>(): [T, (value: T) => void] {
  const history = useHistory();
  const { search } = useLocation();

  const params = parse(search?.slice(1) ?? '') as unknown as T;
  const handleSetParams = useCallback(
    (value: T) => {
      history.push(history.location.pathname + '?' + stringify(value));
    },
    [history],
  );

  return [params, handleSetParams];
}
