import { ToastPosition, useToast } from '@croquiscom/pds';

export function useCopyProductId() {
  const toast = useToast();

  const copyText = (text: string) => {
    navigator.clipboard.writeText(text).then(
      () => {
        toast.show({
          kind: 'success',
          size: 'large',
          position: ToastPosition.BOTTOM_CENTER,
          content: '상품번호 복사 완료',
        });
      },
      () => {
        toast.show({
          kind: 'error',
          size: 'large',
          position: ToastPosition.BOTTOM_CENTER,
          content: '상품번호 복사 실패',
        });
      },
    );
  };
  return copyText;
}
