import {
  useToast,
  HStack,
  IconThumbsUp,
  IconThumbsDown,
  IconColoredThumbsUp,
  IconColoredThumbsDown,
} from '@croquiscom/pds';
import { motion } from 'framer-motion';
import React, { useState } from 'react';
import { useZendesk } from '../hooks/useZendesk';
import { AnswerFeedbackChip } from './AnswerFeedbackChip';
import { ChatbotQnaFeedbackBottomSheet } from './ChatbotQnaFeedbackBottomSheet';
import { SATISFACTION_TYPE } from './constants';
import { FeedbackChipButton } from './styled';
import { chat_bot_session } from './utils/session';
import { usePbl } from '@/hooks/usePbl';
import { useCreateShopChatbotFeedbackMessageMutation } from 'api';

interface AnswerFeedbackProps {
  message_id: string;
  is_show_zendesk?: boolean;
  toast_ref?: React.RefObject<HTMLDivElement>;
  startNewThread: () => void;
}

export const AnswerFeedback = ({ message_id, toast_ref, is_show_zendesk, startNewThread }: AnswerFeedbackProps) => {
  const [current_feedback, setCurrentFeedback] = useState<string>();
  const [feedback_opened, setFeedbackOpened] = useState(false);
  const { pbl } = usePbl();
  const { mutate } = useCreateShopChatbotFeedbackMessageMutation({
    onError() {},
    onSuccess() {
      pbl({
        navigation: 'partner_center_chatbot',
        navigation_sub: {
          sub: 'chat',
        },
        object_id: 'bad',
        category: 'click',
      });
    },
  });
  const { open } = useZendesk();
  const toast = useToast();

  return (
    <>
      <motion.div
        initial={{
          opacity: 0,
          y: 10,
        }}
        animate={{
          opacity: 1,
          y: 0,
        }}
        transition={{
          duration: 0.3,
          delay: 0.3,
        }}
      >
        <HStack spacing={8}>
          <AnswerFeedbackChip
            message_id={message_id}
            satisfaction_type={SATISFACTION_TYPE.SATISFACTION}
            is_cs_connect={false}
            is_no_answer={false}
            object_id='good'
            onSuccess={() => {
              toast.show({
                root: toast_ref?.current,
                kind: 'success',
                content: '답변 평가 완료',
              });
              setCurrentFeedback('good');
            }}
          >
            {current_feedback === 'good' ? <IconColoredThumbsUp /> : <IconThumbsUp />}
          </AnswerFeedbackChip>
          <FeedbackChipButton
            onClick={() => {
              if (current_feedback !== 'bad') {
                mutate({
                  input: {
                    session_id: chat_bot_session.getKey(),
                    message_id,
                    satisfaction_type: SATISFACTION_TYPE.UNSATISFACTION,
                    is_cs_connect: false,
                    is_no_answer: false,
                  },
                });
                setCurrentFeedback('bad');
                setFeedbackOpened(true);
              }
            }}
          >
            {current_feedback === 'bad' ? <IconColoredThumbsDown /> : <IconThumbsDown />}
          </FeedbackChipButton>
          <AnswerFeedbackChip
            message_id={message_id}
            is_cs_connect={false}
            is_no_answer={false}
            object_id='new_chat'
            onSuccess={() => {
              startNewThread();
            }}
          >
            새 대화
          </AnswerFeedbackChip>
          {is_show_zendesk && (
            <AnswerFeedbackChip
              message_id={message_id}
              is_cs_connect={true}
              is_no_answer={false}
              object_id='contact_center'
              onSuccess={() => {
                open();
                pbl({
                  navigation: 'partner_center_chatbot',
                  navigation_sub: {
                    sub: 'chat',
                  },
                  object_id: 'contact_center',
                  category: 'click',
                });
              }}
            >
              상담사 문의
            </AnswerFeedbackChip>
          )}
        </HStack>
      </motion.div>
      <ChatbotQnaFeedbackBottomSheet
        message_id={message_id}
        opened={feedback_opened}
        onDone={() => {
          setCurrentFeedback('bad');
          setFeedbackOpened(false);
          toast.show({
            root: toast_ref?.current,
            kind: 'success',
            content: '답변 평가 완료',
          });
        }}
        onClose={() => {
          setFeedbackOpened(false);
        }}
      />
    </>
  );
};
