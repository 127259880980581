import { HStack, IconCircleQuestion, IconCircleWarning } from '@croquiscom/pds';
import React from 'react';
import { createHelpConstantOptions } from '@/hooks/useHelpConstants';

export enum ChatBotMenuOption {
  CHATBOT_GUIDE = 'CHATBOT_GUIDE',
  CHATBOT_FAQ = 'CHATBOT_FAQ',
}

export const ChatBotMenuOptions = [
  {
    label: (
      <HStack spacing={8} alignment='center'>
        <IconCircleWarning size={20} /> 챗봇 이용 안내
      </HStack>
    ),
    value: ChatBotMenuOption.CHATBOT_GUIDE,
  },
  {
    label: (
      <HStack spacing={8} alignment='center'>
        <IconCircleQuestion size={20} /> 자주 묻는 질문
      </HStack>
    ),
    value: ChatBotMenuOption.CHATBOT_FAQ,
  },
];

export enum SATISFACTION_TYPE {
  SATISFACTION = 'SATISFACTION',
  UNSATISFACTION = 'UNSATISFACTION',
  NONE = 'NONE',
}

export enum FEEDBACK_TYPE {
  INACCURATE_CONTENT = 'INACCURATE_CONTENT',
  LACK_OF_INFORMATION = 'LACK_OF_INFORMATION',
  DIFFICULT_TO_UNDERSTAND = 'DIFFICULT_TO_UNDERSTAND',
  INAPPROPRIATE_LANGUAGE = 'INAPPROPRIATE_LANGUAGE',
  CUSTOM_INPUT = 'CUSTOM_INPUT',
}

export const FEEDBACK_TEXT = {
  [FEEDBACK_TYPE.INACCURATE_CONTENT]: '내용이 정확하지 않아요',
  [FEEDBACK_TYPE.LACK_OF_INFORMATION]: '정보가 부족해요',
  [FEEDBACK_TYPE.DIFFICULT_TO_UNDERSTAND]: '이해하기 어려워요',
  [FEEDBACK_TYPE.INAPPROPRIATE_LANGUAGE]: '부적절한 언어 표현이 있어요',
};

export const CHAT_BOT_FEEDBACK_KEY = 'chatbot.feedback';
export const CHAT_BOT_TOOLTIP_KEY = 'chatbot.tooltip';

export const CHATBOT_STORES = createHelpConstantOptions({
  name: 'ChatBotTargetShopList',
  defaults: {
    베타대상스토어: '',
  },
});

export const CHATBOT_PRESET = createHelpConstantOptions({
  name: 'ChatBot',
  defaults: {},
});

export const FEEDBACK_OPTIONS = [
  { label: '내용이 정확하지 않아요', value: FEEDBACK_TYPE.INACCURATE_CONTENT },
  { label: '정보가 부족해요', value: FEEDBACK_TYPE.LACK_OF_INFORMATION },
  { label: '이해하기 어려워요', value: FEEDBACK_TYPE.DIFFICULT_TO_UNDERSTAND },
  { label: '부적절한 언어 표현이 있어요', value: FEEDBACK_TYPE.INAPPROPRIATE_LANGUAGE },
  { label: '직접 입력', value: FEEDBACK_TYPE.CUSTOM_INPUT },
];

export const ZENDESK_SUGGESTION_KEYWORDS = [
  '고객센터',
  '고객 센터',
  '고객 서비스',
  '고객서비스',
  '상담',
  '일대일',
  '1:1',
  '문의',
];
