import { PageHeader, SectionTitle, Button, HStack, TextButton, IconCircleInfo } from '@croquiscom/pds';
import React, { lazy } from 'react';
import { RoutesType } from '../types';
import { SellerResource } from '@/api';
import ResponsivePageHeader from '@/components/common/responsive/ResponsivePageHeader';
import ExhibitionHeader from '@/components/promotion/exhibition/ExhibitionHeader';
import { PROMOTION_PRICE_DESCRIPTION } from '@/components/promotion/price/common/constants';
import PriceOptimizationPageHeader from '@/components/promotion/price_optimization/common/PriceOptimizationPageHeader';
import { ShopLink } from '@/components/route/ShopLink';

const ShopPromotionApplyPage = lazy(() => import('@/pages/promotion/shop/ShopPromotionApplyPage'));
const ExhibitionApplyPage = lazy(() => import('@/pages/promotion/exhibition/ExhibitionApplyPage'));
const ExhibitionManagementPage = lazy(() => import('@/pages/promotion/exhibition/ExhibitionManagementPage'));
const ShopPromotionListPage = lazy(() => import('@/pages/promotion/shop/ShopPromotionListPage'));
const ShopPromotionDetailPage = lazy(() => import('@/pages/promotion/shop/ShopPromotionDetailPage'));
const FreeShippingManagementPage = lazy(() => import('@/pages/promotion/free_shipping/FreeShippingManagementPage'));
const FreeShippingCreatePage = lazy(() => import('@/pages/promotion/free_shipping/FreeShippingCreatePage'));
const DeductionsPage = lazy(() => import('@/pages/ad/deductions'));
const DeductionsTopBoardPage = lazy(() => import('@/pages/ad/deductions/top_board'));
const ExperienceGroupAgreePage = lazy(() => import('@/pages/promotion/experience_group/ExperienceGroupAgreePage'));
const ExperienceGroupHistoryPage = lazy(() => import('@/pages/promotion/experience_group/ExperienceGroupHistoryPage'));
const ExperienceGroupCreatePage = lazy(() => import('@/pages/promotion/experience_group/ExperienceGroupCreatePage'));
const ExperienceGroupDetailPage = lazy(() => import('@/pages/promotion/experience_group/ExperienceGroupDetailPage'));
const PromotionPriceHistoryPage = lazy(() => import('@/pages/promotion/price/PromotionPriceHistoryPage'));
const PromotionPriceCreatePage = lazy(() => import('@/pages/promotion/price/PromotionPriceCreatePage'));
const PromotionPriceEditPage = lazy(() => import('@/pages/promotion/price/PromotionPriceEditPage'));
const PriceOptimizationPage = lazy(() => import('@/pages/promotion/price_optimization/PriceOptimizationPage'));
const PriceOptimizationIntroPage = lazy(
  () => import('@/pages/promotion/price_optimization/PriceOptimizationIntroPage'),
);

export const PROMOTION_ROUTES: RoutesType[] = [
  {
    current_menu: 'exhibition_management',
    path: '/promotion/exhibition/management',
    params: '',
    component: (
      <>
        <ExhibitionHeader title='스토어픽' show_landing_button={true} />
        <ExhibitionManagementPage />
      </>
    ),
    res: SellerResource.PROMOTION_EXHIBITION_MANAGEMENT,
  },
  {
    current_menu: 'exhibition_apply',
    path: '/promotion/exhibition/apply',
    params: '/:id/data/:dataId',
    component: (
      <>
        <ExhibitionHeader title='스토어픽 참여신청' />
        <ExhibitionApplyPage />
      </>
    ),
    res: SellerResource.PROMOTION_EXHIBITION_APPLY,
  },
  {
    current_menu: 'exhibition_apply',
    path: '/promotion/exhibition/apply',
    params: '',
    component: (
      <>
        <ExhibitionHeader title='스토어픽 참여신청' />
        <ExhibitionApplyPage />
      </>
    ),
    res: SellerResource.PROMOTION_EXHIBITION_APPLY,
  },
  {
    current_menu: 'price_optimization',
    path: '/promotion/price_optimization',
    is_support_mobile: true,
    is_support_app: true,
    params: '',
    component: (
      <>
        <PriceOptimizationPageHeader />
        <PriceOptimizationPage />
      </>
    ),
  },
  {
    current_menu: 'price_optimization_intro',
    path: '/promotion/price_optimization/intro',
    params: '',
    is_support_mobile: true,
    component: (
      <>
        <ResponsivePageHeader title='가격 최적화 관리 소개' />
        <PriceOptimizationIntroPage />
      </>
    ),
  },
  {
    current_menu: 'promotion_management',
    path: '/promotion/shop',
    params: '',
    component: <ShopPromotionListPage />,
  },
  {
    current_menu: 'promotion_management',
    path: '/promotion/shop',
    params: '/:id',
    component: <ShopPromotionDetailPage />,
  },
  {
    current_menu: 'promotion_management',
    path: '/promotion/shop/apply',
    params: '/:id',
    component: <ShopPromotionApplyPage />,
  },
  {
    current_menu: 'promotion_management',
    path: '/promotion/top_board',
    params: '',
    component: <DeductionsTopBoardPage />,
  },
  {
    current_menu: 'free_shipping_management',
    path: '/promotion/free_shipping/management',
    params: '',
    component: (
      <>
        <PageHeader>
          <HStack spacing='auto' alignment='center'>
            <SectionTitle title='무료배송 예약 관리' mb={0} />
            <ShopLink to='/promotion/free_shipping/create'>
              <Button kind='primary'>무료배송 예약하기</Button>
            </ShopLink>
          </HStack>
        </PageHeader>
        <FreeShippingManagementPage />
      </>
    ),
  },
  {
    current_menu: 'free_shipping_create',
    path: '/promotion/free_shipping/create',
    params: '',
    component: (
      <>
        <PageHeader>
          <SectionTitle title='무료배송 예약' mb={0} />
        </PageHeader>
        <FreeShippingCreatePage />
      </>
    ),
  },
  {
    current_menu: 'promotion_apply',
    path: '/promotion/deductions',
    params: '',
    component: <DeductionsPage />,
  },
  {
    current_menu: 'experience_group_agree',
    path: '/promotion/experience_group/agree',
    params: '',
    component: (
      <>
        <PageHeader>
          <SectionTitle
            title='체험단 리뷰 프로모션 약관 동의'
            description='체험단 리뷰 프로모션 등록을 위해 내용을 확인하시고 동의해 주세요.'
            mb={0}
          />
        </PageHeader>
        <ExperienceGroupAgreePage />
      </>
    ),
  },
  {
    current_menu: 'experience_group_history',
    path: '/promotion/experience_group/history',
    params: '',
    component: <ExperienceGroupHistoryPage />,
  },
  {
    current_menu: 'experience_group_create',
    path: '/promotion/experience_group/create',
    params: '',
    component: (
      <>
        <PageHeader>
          <SectionTitle
            title='체험단 리뷰 프로모션 등록'
            textButton={
              <TextButton
                kind='link'
                startIcon={<IconCircleInfo size={16} />}
                onClick={() => window.open('https://partners.kakaostyle.com/help?id=809', '_blank', 'noopener')}
              >
                도움말
              </TextButton>
            }
            mb={0}
          />
        </PageHeader>
        <ExperienceGroupCreatePage />
      </>
    ),
  },
  {
    current_menu: 'experience_group_detail',
    path: '/promotion/experience_group/detail',
    params: '/:id',
    component: (
      <>
        <PageHeader>
          <SectionTitle
            title='체험단 리뷰 프로모션 현황'
            description='체험단 리뷰 프로모션 진행 상품과 선정 유저 내역을 확인하고, 취소 관리할 수 있습니다.'
            mb={0}
          />
        </PageHeader>
        <ExperienceGroupDetailPage />
      </>
    ),
  },
  {
    current_menu: 'promotion_price_history',
    path: '/promotion/price/history',
    params: '',
    component: (
      <>
        <PageHeader>
          <SectionTitle
            title='할인 판매가 관리'
            description={PROMOTION_PRICE_DESCRIPTION}
            actions={
              <ShopLink to='/promotion/price/create'>
                <Button kind='primary'>할인 판매가 등록</Button>
              </ShopLink>
            }
            mb={0}
          />
        </PageHeader>
        <PromotionPriceHistoryPage />
      </>
    ),
  },
  {
    current_menu: 'promotion_price_create',
    path: '/promotion/price/create',
    params: '',
    component: (
      <>
        <PageHeader>
          <SectionTitle title='할인 판매가 등록' description={PROMOTION_PRICE_DESCRIPTION} mb={0} />
        </PageHeader>
        <PromotionPriceCreatePage />
      </>
    ),
  },
  {
    current_menu: 'promotion_price_create',
    path: '/promotion/price/edit',
    params: '',
    component: (
      <>
        <PageHeader>
          <SectionTitle title='할인 판매가 수정' description={PROMOTION_PRICE_DESCRIPTION} mb={0} />
        </PageHeader>
        <PromotionPriceEditPage />
      </>
    ),
  },
];
