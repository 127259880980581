import { BaseText, colors, LineTab, LineTabs, VStack } from '@croquiscom/pds';
import React, { FC } from 'react';
import TransPlainText from '@/components/common/TransPlainText';
import { PartnerPlusGuideBanner } from '@/components/insight/common/PartnerPlusGuideBanner';
import { useHistory } from '@/components/route/LegacyRouterCompat';

interface ManagementNavItemData {
  id: 'benefit' | 'subscription' | 'history' | 'notice';
  label: React.ReactNode;
  href: string;
}

const NAV_ITEMS: ManagementNavItemData[] = [
  {
    id: 'benefit',
    label: <TransPlainText ns='partner_plus' i18nKey='구독 혜택 및 안내' />,
    href: '/partner-plus/management/benefit',
  },
  {
    id: 'subscription',
    label: <TransPlainText ns='partner_plus' i18nKey='구독 관리' />,
    href: '/partner-plus/management/subscription',
  },
  {
    id: 'history',
    label: <TransPlainText ns='partner_plus' i18nKey='결제 내역' />,
    href: '/partner-plus/management/history',
  },
  {
    id: 'notice',
    label: <TransPlainText ns='partner_plus' i18nKey='전용 게시판' />,
    href: '/partner-plus/management/notice',
  },
];

export interface ManagementNavProps {
  current_tab?: 'benefit' | 'subscription' | 'history' | 'notice';
}

const ManagementNav: FC<ManagementNavProps> = ({ current_tab }) => {
  const history = useHistory();
  return (
    <>
      <LineTabs activeTabId={current_tab}>
        {NAV_ITEMS.map((item) => (
          <LineTab
            key={item.id}
            id={item.id}
            onClick={() => {
              history.push(item.href);
            }}
          >
            {item.label}
          </LineTab>
        ))}
      </LineTabs>
      <VStack m='20px 20px 0'>
        <PartnerPlusGuideBanner
          left_node={
            <BaseText kind='Body_13_Regular' color={colors.white}>
              <BaseText kind='Body_13_Bold'>왜 이 상품은 노출이 낮을까?</BaseText> 상품 판매 더이상 고민하지 마세요.{' '}
              <BaseText kind='Body_13_Bold'>파트너플러스</BaseText> 활용법 전해드려요
            </BaseText>
          }
        />
      </VStack>
    </>
  );
};

export default ManagementNav;
