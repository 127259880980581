import { PageHeader, SectionTitle } from '@croquiscom/pds';
import React, { lazy } from 'react';
import { RoutesType } from '../types';
import { SellerResource } from '@/api';
import {
  BEST_REVIEW_EVENT_URL,
  FIRST_COME_FIRST_COME_REVIEW_EVENT_URL,
  FIRST_COME_FIRST_SERVE_REVIEW_EVENT_LIST_URL,
  IMPORT_EXTERNAL_REVIEW_URL,
  ITEM_QUESTION_DETAIL_URL,
  ORDER_INQUIRY_DETAIL_URL,
  PRODUCT_REVIEW_DETAIL_URL,
  PRODUCT_REVIEW_LIST_URL,
  REVIEW_EVENT_LIST_URL,
} from 'rui/components/URL';

const CatalogInquiryPage = lazy(() => import('@/pages/inquiry/CatalogInquiryPage'));
const OrderInquiry = lazy(() => import('@/pages/inquiry/OrderInquiryPage'));
const ProductReviewDetailPage = lazy(() => import('@/pages/review/ProductReviewDetailPage'));
const ProductReviewListPage = lazy(() => import('@/pages/review/ProductReviewListPage'));
const ImportExternalReviewPage = lazy(() => import('@/pages/review/ImportExternalReviewPage'));

const ReviewEventIntroPage = lazy(() => import('@/pages/review-event/ReviewEventIntroPage'));
const ReviewEventListPage = lazy(() => import('@/pages/review-event/ReviewEventListPage'));
const ReviewEventDetailPage = lazy(() => import('@/pages/review-event/best-review/ReviewEventDetailPage'));
const ReviewEventReviewListPage = lazy(() => import('@/pages/review-event/ReviewEventReviewListPage'));
const FirstComeFirstServeReviewEventReviewListPage = lazy(
  () => import('@/pages/review-event/FirstComeFirstServeReviewEventReviewListPage'),
);
const ReviewEventReviewDetailPage = lazy(() => import('@/pages/review-event/ReviewEventReviewDetailPage'));
const FirstComeFirstServeReviewEventDetailPage = lazy(
  () => import('@/pages/review-event/first-come-first-serve-review/FirstComeFirstServeReviewEventDetailPage'),
);

const CatalogInquiryDetailPage = lazy(() => import('@/pages/inquiry/CatalogInquiryDetailPage'));
const OrderInquiryDetailPage = lazy(() => import('@/pages/inquiry/OrderInquiryDetailPage'));

const ShopCustomerInquiryPage = lazy(() => import('@/pages/inquiry/ShopCustomerInquiryPage'));

export const REVIEW_ROUTES: RoutesType[] = [
  {
    is_support_app: true,
    current_menu: 'order_inquiry',
    path: '/order_inquiry',
    params: '',
    is_support_mobile: true,
    component: <OrderInquiry />,
    res: SellerResource.ZPAY_ORDER_INQUIRY_MANAGEMENT,
  },
  {
    is_support_app: true,
    current_menu: 'order_inquiry',
    path: ORDER_INQUIRY_DETAIL_URL,
    params: '/:id',
    is_support_mobile: true,
    component: <OrderInquiryDetailPage />,
    res: SellerResource.ZPAY_ORDER_INQUIRY_MANAGEMENT,
  },
  {
    is_support_app: true,
    current_menu: 'item_question',
    path: ITEM_QUESTION_DETAIL_URL,
    params: '/:id',
    is_support_mobile: true,
    component: <CatalogInquiryDetailPage />,
    res: SellerResource.PRODUCT_QNA_MANAGEMENT,
  },
  {
    is_support_app: true,
    current_menu: 'item_question',
    path: '/item_question',
    params: '',
    is_support_mobile: true,
    component: <CatalogInquiryPage />,
    res: SellerResource.PRODUCT_QNA_MANAGEMENT,
  },
  {
    is_support_app: true,
    current_menu: 'shop_center_inquiry',
    path: '/shop_center_inquiry',
    params: '',
    is_support_mobile: true,
    component: <ShopCustomerInquiryPage />,
    res: SellerResource.CUSTOMER_INQUIRY_MANAGEMENT,
  },
  {
    current_menu: 'product_review',
    path: PRODUCT_REVIEW_DETAIL_URL,
    params: '/:id',
    component: (
      <>
        <PageHeader border={false}>
          <SectionTitle
            title='리뷰 상세'
            description={
              <>
                - 리뷰 내역을 조회/관리할 수 있습니다.
                <br />- 고객에게 지그재그 고객센터 안내 시, 반드시 고객 전용 채널인 1670-8050으로 안내해주세요.
              </>
            }
            mb={0}
          />
        </PageHeader>
        <ProductReviewDetailPage />
      </>
    ),
    res: [SellerResource.PRODUCT_REVIEW_MANAGEMENT_READ, SellerResource.PRODUCT_REVIEW_MANAGEMENT_WRITE],
  },
  {
    current_menu: 'product_review',
    path: PRODUCT_REVIEW_LIST_URL,
    params: '',
    component: (
      <>
        <PageHeader border={false}>
          <SectionTitle
            title='고객 리뷰'
            description={
              <>
                - 리뷰 내역을 조회/관리할 수 있습니다.
                <br />- 고객에게 지그재그 고객센터 안내 시, 반드시 고객 전용 채널인 1670-8050으로 안내해주세요.
              </>
            }
            mb={0}
          />
        </PageHeader>
        <ProductReviewListPage />
      </>
    ),
    res: [SellerResource.PRODUCT_REVIEW_MANAGEMENT_READ, SellerResource.PRODUCT_REVIEW_MANAGEMENT_WRITE],
  },
  {
    current_menu: 'review_event',
    path: REVIEW_EVENT_LIST_URL,
    params: '',
    component: <ReviewEventListPage />,
    res: [SellerResource.REVIEW_EVENT_MANAGEMENT_CREATE, SellerResource.REVIEW_EVENT_MANAGEMENT_WINNER_SELECTION],
  },
  {
    current_menu: 'review_event',
    path: `${REVIEW_EVENT_LIST_URL}/intro`,
    params: '',
    component: <ReviewEventIntroPage />,
    res: [SellerResource.REVIEW_EVENT_MANAGEMENT_CREATE, SellerResource.REVIEW_EVENT_MANAGEMENT_WINNER_SELECTION],
  },
  {
    current_menu: 'review_event',
    path: BEST_REVIEW_EVENT_URL,
    params: '/:id',
    component: <ReviewEventDetailPage />,
    res: [SellerResource.REVIEW_EVENT_MANAGEMENT_CREATE],
  },
  {
    current_menu: 'review_event',
    path: FIRST_COME_FIRST_COME_REVIEW_EVENT_URL,
    params: '/:id',
    component: <FirstComeFirstServeReviewEventDetailPage />,
    res: [SellerResource.REVIEW_EVENT_MANAGEMENT_CREATE],
  },
  {
    current_menu: 'review_event',
    path: REVIEW_EVENT_LIST_URL,
    params: '/:id/review_list',
    component: <ReviewEventReviewListPage />,
    res: [SellerResource.REVIEW_EVENT_MANAGEMENT_CREATE, SellerResource.REVIEW_EVENT_MANAGEMENT_WINNER_SELECTION],
  },
  {
    current_menu: 'review_event',
    path: FIRST_COME_FIRST_SERVE_REVIEW_EVENT_LIST_URL,
    params: '/:id/review_list',
    component: <FirstComeFirstServeReviewEventReviewListPage />,
    res: [SellerResource.REVIEW_EVENT_MANAGEMENT_CREATE, SellerResource.REVIEW_EVENT_MANAGEMENT_WINNER_SELECTION],
  },
  {
    current_menu: 'review_event',
    path: REVIEW_EVENT_LIST_URL,
    params: '/:id/review_list/:review_id',
    component: <ReviewEventReviewDetailPage />,
    res: [SellerResource.REVIEW_EVENT_MANAGEMENT_CREATE, SellerResource.REVIEW_EVENT_MANAGEMENT_WINNER_SELECTION],
  },
  {
    current_menu: 'import_external_review',
    path: IMPORT_EXTERNAL_REVIEW_URL,
    params: '',
    component: (
      <>
        <PageHeader border={false}>
          <SectionTitle
            title='외부 리뷰 연동하기'
            description={
              <>
                크리마 리뷰 서비스를 연동해 자사 쇼핑몰 리뷰를 지그재그에 노출하고,
                <br />
                지그재그 리뷰를 자사 쇼핑몰에 노출할 수 있습니다.
              </>
            }
            mb={0}
          />
        </PageHeader>
        <ImportExternalReviewPage />
      </>
    ),
    res: [],
  },
];
