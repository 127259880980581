import { semantic_colors } from '@croquiscom/pds';
import React from 'react';
import HelperText from './HelperText';

const DataNotReadyMessage: React.FC = () => {
  return (
    <HelperText
      color={semantic_colors.content.tertiary}
      content={
        <>
          추천가 적용 성과는 익일부터 조회 가능
          <br />※ 매일 오전 7시 전날 성과 업데이트
        </>
      }
      text='성과 준비 중'
    />
  );
};

export default DataNotReadyMessage;
