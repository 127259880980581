import { APPLIED_STATE } from '../constants';
import {
  ShopPriceOptimizationTargetProductBasicInfo,
  ShopProductApplyingBestPriceInfo,
  ShopProductBestPriceCandidateInfo,
} from 'api';

export enum BOOSTING_PRICE_STATE {
  // 판매불가로 설정불가
  BLOCKED = 'BLOCKED',
  // 가격설정이 불가능한 외부가격 이슈
  PRICE_INVAILD = 'PRICE_INVAILD',
  // 설정가능
  CAN_APPLY = 'CAN_APPLY',
  // 이미 가격경쟁력이 있는상품(추천가 < 할인판매가)로 설정불가(목록에서 제거예정)
  CAN_NOT_APPLY = 'CAN_NOT_APPLY',
  // 적용 대기중
  WILL_APPLY = 'WILL_APPLY',
  // 재설정 필요
  NEED_RESET = 'NEED_RESET',
  // 적용중
  APPLYING = 'APPLYING',
  // 설정중 일부 실패로 중지예정
  STOP_PARTIAL_FAILED = 'STOP_PARTIAL_FAILED',
  // 중지중 일부 실패로 중지예정
  APPLY_PARTIAL_FAILED = 'APPLY_PARTIAL_FAILED',
}
export enum ACTION_STATE {
  NORMAL = 'NORMAL',
  STOP_PARTIAL_FAILED = 'STOP_PARTIAL_FAILED',
  APPLY_PARTIAL_FAILED = 'APPLY_PARTIAL_FAILED',
}

export const getBoostingPriceState = ({
  applying_best_price_info,
  basic_info,
  best_price_candidate_info,
  is_need_applying,
}: {
  applying_best_price_info?: ShopProductApplyingBestPriceInfo | null;
  basic_info: Pick<ShopPriceOptimizationTargetProductBasicInfo, 'sellable'>;
  best_price_candidate_info?: Pick<ShopProductBestPriceCandidateInfo, 'is_valid_price'>;
  is_need_applying: boolean;
}) => {
  if (!basic_info.sellable) {
    return BOOSTING_PRICE_STATE.BLOCKED;
  }
  if (best_price_candidate_info?.is_valid_price === false) {
    return BOOSTING_PRICE_STATE.PRICE_INVAILD;
  }
  if (!applying_best_price_info) {
    if (!is_need_applying) {
      return BOOSTING_PRICE_STATE.CAN_NOT_APPLY;
    } else {
      return BOOSTING_PRICE_STATE.CAN_APPLY;
    }
  } else {
    if (applying_best_price_info.action_state === ACTION_STATE.APPLY_PARTIAL_FAILED) {
      return BOOSTING_PRICE_STATE.APPLY_PARTIAL_FAILED;
    } else if (applying_best_price_info.action_state === ACTION_STATE.STOP_PARTIAL_FAILED) {
      return BOOSTING_PRICE_STATE.STOP_PARTIAL_FAILED;
    } else if (is_need_applying && applying_best_price_info.state === APPLIED_STATE.ONGOING) {
      return BOOSTING_PRICE_STATE.NEED_RESET;
    } else if (is_need_applying) {
      return BOOSTING_PRICE_STATE.WILL_APPLY;
    } else {
      return BOOSTING_PRICE_STATE.APPLYING;
    }
  }
};

export const isNeedApplying = ({
  best_price_candidate_info,
  basic_info,
}: {
  basic_info: ShopPriceOptimizationTargetProductBasicInfo;
  best_price_candidate_info: ShopProductBestPriceCandidateInfo;
}) => {
  return (
    basic_info.promotion_discount_price + basic_info.current_base_shipping_fee >
    best_price_candidate_info.best_price_candidate_1.price +
      best_price_candidate_info.best_price_candidate_1.base_shipping_fee
  );
};
