import { Badge, IconColoredFire, IconCircleQuestion, Alert, Popover, HelpText, VStack } from '@croquiscom/pds';
import styled from '@emotion/styled';
import React from 'react';
import useMediaQuery from '@/hooks/useMediaQuery';

const PromotionProductBadge: React.FC = () => {
  const { is_desktop } = useMediaQuery();
  if (is_desktop) {
    return (
      <Popover
        placement='top'
        content={
          <HelpText
            contents={[{ text: '기획전에 선정된 상품이거나 기획전 쿠폰이 적용되는 상품' }]}
            title='기획전 상품'
          />
        }
      >
        <VStack>
          <SC.Badge
            kind='fill'
            shape='rectangle'
            size='medium'
            color='red'
            startIcon={<IconColoredFire size={12} />}
            endIcon={<IconCircleQuestion size={12} />}
          >
            기획전
          </SC.Badge>
        </VStack>
      </Popover>
    );
  } else {
    return (
      <span
        onClick={() => {
          Alert({
            title: '기획전 상품',
            text: '기획전에 선정된 상품이거나 기획전 쿠폰이 적용되는 상품',
          });
        }}
      >
        <SC.Badge
          kind='fill'
          shape='rectangle'
          size='medium'
          color='red'
          startIcon={<IconColoredFire size={12} />}
          endIcon={<IconCircleQuestion size={12} />}
        >
          기획전
        </SC.Badge>
      </span>
    );
  }
};

export default PromotionProductBadge;

const SC = {
  Badge: styled(Badge)`
    cursor: pointer;
  `,
};
