import React from 'react';
import { GuideConstantButton } from '@/components/common/guide/GuideConstantButton';
import { createHelpConstantOptions } from '@/hooks/useHelpConstants';

export const BENEFIT_PRESET = createHelpConstantOptions({
  name: '혜택 및 프로모션',
  defaults: {
    '혜택 관리 소개':
      'https://docs.google.com/presentation/d/1iCbA_KqCvKsze7L6jKC_Edluovfjb_LkCYbsXiKHiGU/edit#slide=id.g1f03fde392a_1_67',
    '쿠폰 조회': 'https://partners.kakaostyle.com/help?id=990',
    '쿠폰 리포트': 'https://partners.kakaostyle.com/help?id=805',
    '쿠폰 발행': 'https://partners.kakaostyle.com/help?id=804',
  },
});

export const GUIDE_BENEFIT = {
  BENEFIT_INTRO: (
    <GuideConstantButton options={BENEFIT_PRESET} linkKey='혜택 관리 소개'>
      혜택 관리 소개
    </GuideConstantButton>
  ),
  COUPON_LIST_HELP: (
    <GuideConstantButton options={BENEFIT_PRESET} linkKey='쿠폰 조회'>
      쿠폰 조회
    </GuideConstantButton>
  ),
  COUPON_REPORT_HELP: (
    <GuideConstantButton options={BENEFIT_PRESET} linkKey='쿠폰 리포트'>
      쿠폰 리포트
    </GuideConstantButton>
  ),
  COUPON_ADD_HELP: (
    <GuideConstantButton options={BENEFIT_PRESET} linkKey='쿠폰 발행'>
      도움말 보기
    </GuideConstantButton>
  ),
};
