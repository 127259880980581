import { BaseText, colors, HStack, IconLinkExternal, ImagePartnerPlusTextWh } from '@croquiscom/pds';
import styled from '@emotion/styled';
import { FC, isValidElement, ReactNode } from 'react';
import { useLocation } from 'react-router-dom';
import { usePbl } from '@/hooks/usePbl';
import { useSearchParams } from '@/hooks/useSearchParams';

interface Props {
  left_node: string | ReactNode;
}

function extractString(obj: string | ReactNode | (string | ReactNode)[]): string {
  if (typeof obj === 'string') return obj;
  else if (isValidElement(obj)) {
    return extractString(obj.props.children);
  } else if (Array.isArray(obj)) {
    return obj.map((e) => extractString(e)).join(' ');
  } else return obj?.toString() ?? '';
}

export const PartnerPlusGuideBanner: FC<Props> = ({ left_node }) => {
  const location = useLocation();
  const [params] = useSearchParams();
  const { pbl } = usePbl();
  const left =
    typeof left_node === 'string' ? (
      <BaseText color={colors.white} kind='Body_13_Bold'>
        {left_node}
      </BaseText>
    ) : (
      left_node
    );

  const handleClick = () => {
    const { pathname } = location;
    const last_path = pathname.split('/').pop();
    const ug = params.ug;
    const only_text_from_node = extractString(left_node);

    pbl({
      category: 'click',
      navigation: last_path ?? '',
      object_id: 'guide',
      object_section: 'partnerplus_guide',
      object_type: 'banner',
      object_idx: '0',
      object_url: 'https://croquis.notion.site/1423dd4dd5b18024a913c6c056e2373d?pvs=4',
      navigation_sub: {
        page_id: ug,
      },
      extra: JSON.stringify({
        title: only_text_from_node,
      }),
    });
  };

  return (
    <a
      onClick={handleClick}
      target='_blank'
      rel='noopener noreferrer'
      href='https://croquis.notion.site/1423dd4dd5b18024a913c6c056e2373d?pvs=4'
    >
      <SC.Container>
        {left}
        <HStack alignment='center' spacing={4}>
          <ImagePartnerPlusTextWh width={74} height={12} />
          <BaseText color={colors.white} kind='Body_13_Bold'>
            공식 가이드북
          </BaseText>
          <IconLinkExternal color={colors.white} />
        </HStack>
      </SC.Container>
    </a>
  );
};

const SC = {
  Container: styled.div`
    background: url('https://cf.res.s.alpha.zigzag.kr/pds/images/partner-plus/banner_bg.jpg') lightgray 50% / cover
      no-repeat;
    height: 46px;
    border-radius: 8px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 16px;
  `,
};
