import { BaseText, Divider, HStack, Modal, VStack, colors, semantic_colors, text_styles } from '@croquiscom/pds';
import styled from '@emotion/styled';
import React, { Fragment } from 'react';
import { FAQ_CONTENTS } from '../constants';
import ExampleSwiper from '../intro/ExampleSwiper';
import Example_1 from '@/assets/images/promotion/price_optimization/example_1.png';
import Example_2 from '@/assets/images/promotion/price_optimization/example_2.png';
import Example_3 from '@/assets/images/promotion/price_optimization/example_3.png';
import Example_4 from '@/assets/images/promotion/price_optimization/example_4.png';
import ResponsiveText from '@/components/common/responsive/ResponsiveText';
import ResponsiveVStack from '@/components/common/responsive/ResponsiveVStack';
import useMediaQuery from '@/hooks/useMediaQuery';

interface ExampleModalProps {
  opened: boolean;
  onClose: () => void;
}

const ExampleModal: React.FC<ExampleModalProps> = ({ opened, onClose }) => {
  const { is_desktop } = useMediaQuery();
  return (
    <Modal
      title='노출 확대 안내'
      width={720}
      contentProps={{ scrollable: true, p: is_desktop ? 24 : 0, pb: 0 }}
      fullScreen={!is_desktop}
      opened={opened}
      onCancel={onClose}
    >
      <ResponsiveVStack spacing={{ desktop: 16, mobile: 24 }}>
        {FAQ_CONTENTS.map(({ q, a }) => (
          <Fragment key={q}>
            <ResponsiveVStack p={{ mobile: '16px 16px 0' }} spacing={{ mobile: 4 }}>
              <BaseText kind='Heading_15_Bold'>{q}</BaseText>
              <ResponsiveText kind={{ mobile: 'Body_14_Regular' }} color={semantic_colors.content.secondary}>
                {a}
              </ResponsiveText>
            </ResponsiveVStack>
            <ResponsiveVStack p={{ mobile: '16px 16px 0' }}>
              <Divider />
            </ResponsiveVStack>
          </Fragment>
        ))}
        <VStack spacing={24} alignment='center'>
          <ResponsiveVStack width='100%' alignment='leading' p={{ mobile: '0 16px' }}>
            <BaseText kind='Heading_15_Bold'>노출 확대는 어디서 적용되나요?</BaseText>
            <BaseText color={semantic_colors.content.secondary}>
              * 최저가도전 뱃지는 가격 최적화된 상품 중 일부 적용됩니다.
            </BaseText>
          </ResponsiveVStack>
          {is_desktop ? (
            <HStack spacing={24}>
              <VStack width={150} spacing={10} alignment='center'>
                <SC.Badge>검색 지면</SC.Badge>
                <SC.Image width='100%' src={Example_1} alt='' />
              </VStack>
              <VStack width={150} spacing={10} alignment='center'>
                <SC.Badge>카테고리 지면</SC.Badge>
                <SC.Image width='100%' src={Example_2} alt='' />
              </VStack>
              <VStack width={150} spacing={10} alignment='center'>
                <SC.Badge>상품 상세페이지 내 추천 지면</SC.Badge>
                <SC.Image width='100%' src={Example_3} alt='' />
              </VStack>
              <VStack width={150} spacing={10} alignment='center'>
                <SC.Badge>최저가도전 뱃지</SC.Badge>
                <SC.Image width='100%' src={Example_4} alt='' />
              </VStack>
            </HStack>
          ) : (
            <ExampleSwiper />
          )}
        </VStack>
      </ResponsiveVStack>
    </Modal>
  );
};

export default ExampleModal;

const SC = {
  ImageContainer: styled(VStack)`
    border-radius: 10px;
    border: 2px solid ${semantic_colors.border.default};
    overflow: hidden;
  `,
  Image: styled.img`
    width: 100%;
    object-fit: cover;
  `,
  Badge: styled(HStack)`
    padding: 2px 10px;
    color: ${semantic_colors.content.secondary};
    ${text_styles.Caption_10_SemiBold};
    border-radius: 63px;
    background-color: ${colors.gray300};
  `,
};
