import { BaseText, DataTable, Divider, HStack, Modal, VStack, colors, semantic_colors } from '@croquiscom/pds';
import styled from '@emotion/styled';
import orderBy from 'lodash/orderBy';
import React, { useMemo } from 'react';
import BoostingProductInfo from '../common/BoostingProductInfo';
import ChartHeader from '../common/ChartHeader';
import HelperText from '../common/HelperText';
import { BOOSTING_HISTORY_BY_PRODUCT_COLUMNS } from '../constants';
import { SummaryCard } from '../style';
import BoostingProductChart from './contents/BoostingProductChart';
import { useGetProductBoostingPerformanceQuery } from 'api';

interface ProductPerformanceModalProps {
  product_id: string;
  opened: boolean;
  onClose: () => void;
}

const ProductPerformanceModal: React.FC<ProductPerformanceModalProps> = ({ product_id, opened, onClose }) => {
  const { data } = useGetProductBoostingPerformanceQuery({ product_id }, { enabled: opened });
  const performance_data = data?.shop_product_impressions_performance;
  const list_data = useMemo(() => {
    return orderBy(
      (performance_data?.daily_impressions_summary_list_during_optimization ?? []).map((item) => ({
        ...item,
        key: item.date_ymd,
      })),
      ['date_ymd'],
      ['desc'],
    );
  }, [data]);
  return (
    <Modal
      title='상품별 노출 성과'
      width={900}
      contentProps={{ scrollable: true, p: 0, pb: 24 }}
      opened={opened}
      onCancel={onClose}
    >
      <VStack>
        <SC.DescriptionContainer p='16px 24px'>
          <BaseText color={semantic_colors.content.secondary}>
            확대 효과가 적용된 기간 동안, 효과가 발생한 지면에서의 노출 성과를 확인할 수 있습니다.
          </BaseText>
        </SC.DescriptionContainer>
        <VStack p={24} spacing={32}>
          <VStack spacing={8}>
            <BaseText kind='Heading_15_Bold'>상품 정보</BaseText>
            {performance_data && (
              <SC.ProductInfo>
                <BoostingProductInfo
                  basic_info={performance_data}
                  group_list={performance_data.group_list}
                  sellable={performance_data.sellable}
                  is_modal
                />
              </SC.ProductInfo>
            )}
          </VStack>
          {performance_data?.recent_impressions_summary_during_optimization && (
            <VStack spacing={8}>
              <HStack spacing={10} alignment='center'>
                <BaseText kind='Heading_15_Bold'>현재 적용 중인 노출 확대 성과</BaseText>
              </HStack>
              <SummaryCard bg={semantic_colors.background.surface}>
                <VStack width={238}>
                  <HelperText
                    text='노출 확대 효과'
                    content='노출 확대 적용 기간 동안, 노출 확대 가능 지면에서 기존 대비 노출 상승률'
                  />
                  <BaseText kind='Heading_20_Bold' color={colors.green500}>
                    {(
                      (performance_data?.recent_impressions_summary_during_optimization
                        ?.estimated_impressions_increased_rate_bp ?? 0) / 100
                    ).toLocaleString()}
                    %
                  </BaseText>
                </VStack>
                <Divider direction='vertical' color={colors.gray300} length={52} />
                <VStack width={238}>
                  <HelperText
                    text='추가 노출 수'
                    content='노출 확대 적용 기간 동안, 노출 확대 가능 지면에서 확대 효과가 적용되어 기존 대비 추가로 노출된 횟수'
                  />
                  <BaseText kind='Heading_20_Bold' color={colors.green500}>
                    {performance_data?.recent_impressions_summary_during_optimization?.estimated_increased_impressions.toLocaleString() ??
                      '-'}
                    회
                  </BaseText>
                </VStack>
                <Divider direction='vertical' color={colors.gray300} length={52} />
                <VStack width={238}>
                  <HelperText
                    text='확대 적용일 총 노출 수'
                    content={
                      <>
                        노출 확대 적용 기간 동안, 노출 확대 가능 지면에서 고객에게 노출된 총 횟수
                        <br />※ 기존 노출 수 + 추가 노출 수
                      </>
                    }
                  />
                  <BaseText kind='Heading_20_Bold' color={colors.green500}>
                    {performance_data?.recent_impressions_summary_during_optimization?.total_impressions_in_boosting_period.toLocaleString() ??
                      '-'}
                    회
                  </BaseText>
                </VStack>
                <Divider direction='vertical' color={colors.gray300} length={52} />
                <VStack width={238}>
                  <HelperText text='확대 적용일' content='노출 확대 효과가 적용된 일 수' />
                  <BaseText kind='Heading_20_Bold' color={colors.green500}>
                    {performance_data?.recent_impressions_summary_during_optimization?.boosting_duration_days.toLocaleString() ??
                      '-'}
                    일
                  </BaseText>
                </VStack>
              </SummaryCard>
            </VStack>
          )}
          <VStack spacing={8}>
            <SC.ChartContainer>
              <VStack spacing={8}>
                <HStack spacing={10} alignment='center'>
                  <BaseText kind='Heading_15_Bold'>지난 30일 노출 성과</BaseText>
                  <BaseText kind='Body_12_Regular' color={semantic_colors.content.secondary}>
                    30일간 누적된 노출 확대 성과를 종합해 확인할 수 있습니다.
                  </BaseText>
                </HStack>
                <SummaryCard border_color='transparent'>
                  <VStack width={238}>
                    <HelperText
                      text='노출 확대 효과'
                      content='노출 확대 적용 기간 동안, 기존 노출 수 대비 추가된 노출 비율'
                    />
                    <BaseText kind='Heading_18_SemiBold' color={colors.green500}>
                      {(
                        (performance_data?.impressions_summary_during_optimization
                          .estimated_impressions_increased_rate_bp ?? 0) / 100
                      ).toLocaleString()}
                      %
                    </BaseText>
                  </VStack>
                  <Divider direction='vertical' color={colors.gray300} length={52} />
                  <VStack width={238}>
                    <HelperText
                      text='추가 노출 수'
                      content='노출 확대 적용 기간 동안, 확대 효과가 적용되어 기존보다 추가로 노출된 횟수'
                    />
                    <BaseText kind='Heading_18_SemiBold' color={colors.green500}>
                      {performance_data?.impressions_summary_during_optimization.estimated_increased_impressions.toLocaleString() ??
                        '0'}
                      회
                    </BaseText>
                  </VStack>
                  <Divider direction='vertical' color={colors.gray300} length={52} />
                  <VStack width={238}>
                    <HelperText
                      text='확대 적용일 총 노출 수'
                      content='노출 확대가 적용된 기간 동안, 해당 상품이 고객에게 노출된 총 횟수'
                    />
                    <BaseText kind='Heading_18_SemiBold' color={colors.green500}>
                      {performance_data?.impressions_summary_during_optimization.total_impressions_in_boosting_period.toLocaleString() ??
                        '0'}
                      회
                    </BaseText>
                  </VStack>
                  <Divider direction='vertical' color={colors.gray300} length={52} />
                  <VStack width={238}>
                    <HelperText text='확대 적용일' content='지난 30일간 노출 확대가 적용된 총 일수' />
                    <BaseText kind='Heading_18_SemiBold' color={colors.green500}>
                      {performance_data?.impressions_summary_during_optimization.boosting_duration_days.toLocaleString() ??
                        '0'}
                      일
                    </BaseText>
                  </VStack>
                </SummaryCard>
              </VStack>
              <ChartHeader
                title='노출 수'
                legend={[
                  {
                    color: colors.green400,
                    label: '추가 노출 수',
                  },
                ]}
              />
              <BoostingProductChart
                stat_data={
                  data?.shop_product_impressions_performance?.daily_impressions_summary_list_during_optimization ?? []
                }
              />
            </SC.ChartContainer>
            <DataTable columns={BOOSTING_HISTORY_BY_PRODUCT_COLUMNS} rows={list_data} />
          </VStack>
        </VStack>
      </VStack>
    </Modal>
  );
};

export default ProductPerformanceModal;

const SC = {
  DescriptionContainer: styled(VStack)`
    background-color: ${colors.gray100};
  `,
  ProductInfo: styled(HStack)`
    padding: 12px;
    gap: 10px;
    border: 1px solid ${semantic_colors.border.default};
    border-radius: 10px;
  `,
  ChartContainer: styled(VStack)`
    padding: 20px;
    row-gap: 10px;
    border: 1px solid ${semantic_colors.border.default};
    border-radius: 10px;
  `,
};
