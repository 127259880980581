import React from 'react';
import TransPlainText from '../TransPlainText';
import { GuideConstantButton } from '@/components/common/guide/GuideConstantButton';
import { createHelpConstantOptions } from '@/hooks/useHelpConstants';

const GUIDE_OPTIONS = createHelpConstantOptions({
  name: '영상 가이드 URL',
  defaults: {
    'shipping_penalty 영상 가이드 (소개)':
      'https://kakaostyleincubating.oopy.io/guide/product/product_shipping-eta?utm_source=admin&utm_medium=txt&utm_campaign=guide&utm_content=&utm_term=',
    'shipping_penalty 영상 가이드 (관리)':
      'https://kakaostyleincubating.oopy.io/guide/product/product_shipping-eta-manage?utm_source=admin&utm_medium=txt&utm_campaign=guide&utm_content=&utm_term=',
    'catalog/item_detail/new 영상 가이드1':
      'https://kakaostyleincubating.oopy.io/guide/product/product_direct?utm_source=admin&utm_medium=txt&utm_campaign=guide&utm_content=&utm_term=',
    'catalog/item_detail/new 영상 가이드2':
      'https://kakaostyleincubating.oopy.io/tip/product-name?utm_source=admin&utm_medium=txt&utm_campaign=guide&utm_content=&utm_term=',
    'catalog/item_detail/new 영상 가이드3':
      'https://kakaostyleincubating.oopy.io/guide/product/seo?utm_source=admin&utm_medium=txt&utm_campaign=guide&utm_content=&utm_term=',
    'catalog/item_list 영상 가이드 (상품 목록)':
      'https://kakaostyleincubating.oopy.io/guide/product/product_list-check?utm_source=admin&utm_medium=txt&utm_campaign=guide&utm_content=&utm_term=',
    'catalog/item_list 영상 가이드 (상품 노출 진열)':
      'https://kakaostyleincubating.oopy.io/guide/product/product_display?utm_source=admin&utm_medium=txt&utm_campaign=guide&utm_content=&utm_term=',
    'excel_upload?tab=catalog 영상 가이드':
      'https://kakaostyleincubating.oopy.io/guide/product/product_excel?utm_source=admin&utm_medium=txt&utm_campaign=guide&utm_content=&utm_term=',
    'item_question 영상 가이드':
      'https://kakaostyleincubating.oopy.io/guide/qna-review/qna-basic?utm_source=admin&utm_medium=txt&utm_campaign=guide&utm_content=&utm_term=',
    'order_inquiry 영상 가이드':
      'https://kakaostyleincubating.oopy.io/guide/qna-review/qna-basic?utm_source=admin&utm_medium=txt&utm_campaign=guide&utm_content=&utm_term=',
    'info/shipping_address 영상 가이드':
      'https://kakaostyleincubating.oopy.io/guide/management/shipping-fee?utm_source=admin&utm_medium=txt&utm_campaign=guide&utm_content=&utm_term=',
    'info/shop_sync 영상 가이드':
      'https://kakaostyleincubating.oopy.io/guide/product/product_api?utm_source=admin&utm_medium=txt&utm_campaign=guide&utm_content=&utm_term=',
    'info/shop_sync?mode=EditConfigs 영상 가이드':
      'https://kakaostyleincubating.oopy.io/guide/management/api-shipping-fee?utm_source=admin&utm_medium=txt&utm_campaign=guide&utm_content=&utm_term=',
    'store_home/main_banner/list 영상 가이드':
      'https://kakaostyleincubating.oopy.io/guide/storehome/mainbanner?utm_source=admin&utm_medium=txt&utm_campaign=guide&utm_content=&utm_term=',
    'store_home/collection/list 영상 가이드':
      'https://kakaostyleincubating.oopy.io/guide/storehome/collection?utm_source=admin&utm_medium=txt&utm_campaign=guide&utm_content=&utm_term=',
    'order_item/list/exchange 영상 가이드':
      'https://kakaostyleincubating.oopy.io/guide/order/order-change?utm_source=admin&utm_medium=txt&utm_campaign=guide&utm_content=&utm_term=',
    'order_item/list/return 영상 가이드':
      'https://kakaostyleincubating.oopy.io/guide/order/order-back?utm_source=admin&utm_medium=txt&utm_campaign=guide&utm_content=&utm_term=',
    'order_item/list/pre_shipment 영상 가이드':
      'https://kakaostyleincubating.oopy.io/guide/order/order-first?utm_source=admin&utm_medium=txt&utm_campaign=guide&utm_content=&utm_term=',
    'order_item/list/cancel 영상 가이드':
      'https://kakaostyleincubating.oopy.io/guide/order/order-cancel?utm_source=admin&utm_medium=txt&utm_campaign=guide&utm_content=&utm_term=',
    'coupon/my_pick/new 영상 가이드':
      'https://kakaostyleincubating.oopy.io/guide/benefit/coupon-mypick?utm_source=admin&utm_medium=txt&utm_campaign=guide&utm_content=&utm_term=',
  },
});

export const GUIDE_SHIPPING_PENALTY = {
  INTRODUCE: (
    <GuideConstantButton options={GUIDE_OPTIONS} linkKey='shipping_penalty 영상 가이드 (소개)'>
      <TransPlainText ns='info/guide' i18nKey='발송예정일 소개(영상 가이드)' />
    </GuideConstantButton>
  ),
  MANAGE: (
    <GuideConstantButton options={GUIDE_OPTIONS} linkKey='shipping_penalty 영상 가이드 (관리)'>
      <TransPlainText ns='info/guide' i18nKey='발송예정일 관리 방법(영상 가이드)' />
    </GuideConstantButton>
  ),
};
export const GUIDE_CATALOG_ITEM_DETAIL = {
  NEW_1: (
    <GuideConstantButton options={GUIDE_OPTIONS} linkKey='catalog/item_detail/new 영상 가이드1'>
      <TransPlainText ns='info/guide' i18nKey='상품 등록 방법(영상 가이드)' />
    </GuideConstantButton>
  ),
  NEW_2: (
    <GuideConstantButton options={GUIDE_OPTIONS} linkKey='catalog/item_detail/new 영상 가이드2'>
      <TransPlainText ns='info/guide' i18nKey='상품명 Tip(영상 가이드)' />
    </GuideConstantButton>
  ),
  NEW_3: (
    <GuideConstantButton options={GUIDE_OPTIONS} linkKey='catalog/item_detail/new 영상 가이드3'>
      <TransPlainText ns='info/guide' i18nKey='상품 검색 속성 Tip(영상 가이드)' />
    </GuideConstantButton>
  ),
  LIST_1: (
    <GuideConstantButton options={GUIDE_OPTIONS} linkKey='catalog/item_list 영상 가이드 (상품 목록)'>
      <TransPlainText ns='info/guide' i18nKey='상품 목록 및 조회(영상 가이드)' />
    </GuideConstantButton>
  ),
  LIST_2: (
    <GuideConstantButton options={GUIDE_OPTIONS} linkKey='catalog/item_list 영상 가이드 (상품 노출 진열)'>
      <TransPlainText ns='info/guide' i18nKey='노출 진열 판매 관리(영상 가이드)' />
    </GuideConstantButton>
  ),
  EXCEL_UPLOAD: (
    <GuideConstantButton options={GUIDE_OPTIONS} linkKey='excel_upload?tab=catalog 영상 가이드'>
      <TransPlainText ns='info/guide' i18nKey='영상 가이드' />
    </GuideConstantButton>
  ),
};

export const GUIDE_QUESTION = {
  ITEM_QUESTION: (
    <GuideConstantButton options={GUIDE_OPTIONS} linkKey='item_question 영상 가이드'>
      <TransPlainText ns='info/guide' i18nKey='영상 가이드' />
    </GuideConstantButton>
  ),
  ORDER_INQUIRY: (
    <GuideConstantButton options={GUIDE_OPTIONS} linkKey='order_inquiry 영상 가이드'>
      <TransPlainText ns='info/guide' i18nKey='영상 가이드' />
    </GuideConstantButton>
  ),
};
export const GUIDE_INFO = {
  SHIPPING_ADDRESS: (
    <GuideConstantButton options={GUIDE_OPTIONS} linkKey='info/shipping_address 영상 가이드'>
      <TransPlainText ns='info/guide' i18nKey='영상 가이드' />
    </GuideConstantButton>
  ),
  SHOP_SYNC: (
    <GuideConstantButton options={GUIDE_OPTIONS} linkKey='info/shop_sync 영상 가이드'>
      <TransPlainText ns='info/guide' i18nKey='영상 가이드' />
    </GuideConstantButton>
  ),
  SHOP_SYNC_EDITCONFIGS: (
    <GuideConstantButton options={GUIDE_OPTIONS} linkKey='info/shop_sync?mode=EditConfigs 영상 가이드'>
      <TransPlainText ns='info/guide' i18nKey='영상 가이드' />
    </GuideConstantButton>
  ),
};
export const GUIDE_STORE_HOME = {
  MAIN_BANNER_LIST: (
    <GuideConstantButton options={GUIDE_OPTIONS} linkKey='store_home/main_banner/list 영상 가이드'>
      <TransPlainText ns='info/guide' i18nKey='영상 가이드' />
    </GuideConstantButton>
  ),
  COLLECTION_LIST: (
    <GuideConstantButton options={GUIDE_OPTIONS} linkKey='store_home/collection/list 영상 가이드'>
      <TransPlainText ns='info/guide' i18nKey='영상 가이드' />
    </GuideConstantButton>
  ),
};
export const GUIDE_ORDER_ITEM = {
  EXCHANGE: (
    <GuideConstantButton options={GUIDE_OPTIONS} linkKey='order_item/list/exchange 영상 가이드'>
      <TransPlainText ns='info/guide' i18nKey='영상 가이드' />
    </GuideConstantButton>
  ),
  RETURN: (
    <GuideConstantButton options={GUIDE_OPTIONS} linkKey='order_item/list/return 영상 가이드'>
      <TransPlainText ns='info/guide' i18nKey='영상 가이드' />
    </GuideConstantButton>
  ),
  SEARCH: (
    <GuideConstantButton options={GUIDE_OPTIONS} linkKey='order_item/list/pre_shipment 영상 가이드'>
      <TransPlainText ns='info/guide' i18nKey='영상 가이드' />
    </GuideConstantButton>
  ),
  CANCEL: (
    <GuideConstantButton options={GUIDE_OPTIONS} linkKey='order_item/list/cancel 영상 가이드'>
      <TransPlainText ns='info/guide' i18nKey='영상 가이드' />
    </GuideConstantButton>
  ),
};
