import { Button, IconCircleInfo, PageHeader, SectionTitle, TextButton } from '@croquiscom/pds';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { SellerResource } from '@/api';
import { AD_GUIDE_OPTIONS } from '@/components/ad/common/constants';
import { ShopLink } from '@/components/route/ShopLink';
import { useHelpConstants } from '@/hooks/useHelpConstants';
import { useInfo } from '@/hooks/useInfo';
import { STORE_PICK_EXHIBITION_URL } from 'rui/components/URL';

interface Props {
  title: string;
  show_landing_button?: boolean;
}

const ExhibitionHeader: FC<Props> = ({ title, show_landing_button }) => {
  const { isAllowed } = useInfo();
  const { t } = useTranslation('promotion');
  const { data } = useHelpConstants(AD_GUIDE_OPTIONS);

  const use_action = show_landing_button && isAllowed([SellerResource.PROMOTION_EXHIBITION_APPLY]);

  return (
    <PageHeader>
      <SectionTitle
        title={title}
        description={t('고객 대상으로 발행한 프로모션/이벤트 페이지를 관리하는 영역입니다.')}
        actions={
          use_action ? (
            <ShopLink to={STORE_PICK_EXHIBITION_URL}>
              <Button kind='primary'>스토어픽 참여신청</Button>
            </ShopLink>
          ) : undefined
        }
        textButton={
          <TextButton
            kind='link'
            startIcon={<IconCircleInfo width={16} height={16} />}
            onClick={() => window.open(data['프로모션 관리 소개서'])}
          >
            {t('프로모션 관리 소개서')}
          </TextButton>
        }
        mb={0}
      />
    </PageHeader>
  );
};

export default ExhibitionHeader;
