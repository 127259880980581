import { Button, PageHeader, SectionTitle, TextButton, IconCircleInfo, HStack, Divider } from '@croquiscom/pds';
import React, { lazy } from 'react';
import { SellerResource } from '@/api';
import { GUIDE_BENEFIT } from '@/components/coupon/common/constants';
import { ShopLink } from '@/components/route/ShopLink';
import { RoutesType } from '@/routes/types';

const MyPickCouponEditPage = lazy(() => import('@/pages/coupon/MyPickCouponEditPage'));
const MyPickCouponIntroPage = lazy(() => import('@/pages/coupon/MyPickCouponIntroPage'));
const MyPickCouponListPage = lazy(() => import('@/pages/coupon/MyPickCouponListPage'));
const MyPickCouponReportPage = lazy(() => import('@/pages/coupon/MyPickCouponReportPage'));
const CouponEditPage = lazy(() => import('@/pages/coupon/CouponEditPage'));
const CouponListPage = lazy(() => import('@/pages/coupon/CouponListPage'));
const CouponReportDetailPage = lazy(() => import('@/pages/coupon/CouponReportDetailPage'));
const PointAddPage = lazy(() => import('@/pages/point/PointAddPage'));
const PointListPage = lazy(() => import('@/pages/point/PointListPage'));

export const BENEFIT_ROUTES: RoutesType[] = [
  {
    current_menu: 'promotion_coupon_my_pick_intro',
    path: '/coupon/my_pick/intro',
    params: '',
    component: (
      <>
        <PageHeader>
          <SectionTitle title='마이픽쿠폰 소개' mb={0} />
        </PageHeader>
        <MyPickCouponIntroPage />
      </>
    ),
  },
  {
    current_menu: 'promotion_coupon_my_pick_new',
    path: '/coupon/my_pick/new',
    params: '',
    component: (
      <>
        <PageHeader>
          <SectionTitle title='마이픽쿠폰 신청' mb={0} />
        </PageHeader>
        <MyPickCouponEditPage />
      </>
    ),
  },
  {
    current_menu: 'promotion_coupon_my_pick_edit',
    path: '/coupon/my_pick/edit',
    params: '/:id',
    component: (
      <>
        <PageHeader>
          <SectionTitle title='마이픽쿠폰 수정' mb={0} />
        </PageHeader>
        <MyPickCouponEditPage />
      </>
    ),
  },
  {
    current_menu: 'promotion_coupon_my_pick_list',
    path: '/coupon/my_pick/list',
    params: '',
    component: (
      <>
        <PageHeader>
          <SectionTitle
            title='마이픽쿠폰'
            description='7일간 마이픽쿠폰을 노출하여 잠재고객을 만날 수 있습니다.'
            textButton={
              <TextButton kind='link' startIcon={<IconCircleInfo width={16} height={16} />}>
                <ShopLink to='/coupon/my_pick/intro'>마이픽쿠폰 소개</ShopLink>
              </TextButton>
            }
            actions={
              <ShopLink to='/coupon/my_pick/new'>
                <Button kind='primary'>마이픽쿠폰 신청</Button>
              </ShopLink>
            }
            mb={0}
          />
        </PageHeader>
        <MyPickCouponListPage />
      </>
    ),
  },
  {
    current_menu: 'promotion_coupon_my_pick_detail',
    path: '/coupon/my_pick/list',
    params: '/:id',
    component: (
      <>
        <PageHeader>
          <SectionTitle title='마이픽쿠폰 리포트' mb={0} />
        </PageHeader>
        <MyPickCouponReportPage />
      </>
    ),
  },
  {
    current_menu: 'coupon_add',
    path: '/coupon/add',
    params: '',
    component: (
      <>
        <PageHeader>
          <SectionTitle
            title='쿠폰 발행'
            description='발급 대상 타입을 정하여 쿠폰을 발행할 수 있습니다.'
            textButton={
              <HStack spacing={6} alignment='center'>
                {GUIDE_BENEFIT.BENEFIT_INTRO}
                <Divider direction='vertical' style={{ height: 12 }} />
                {GUIDE_BENEFIT.COUPON_ADD_HELP}
              </HStack>
            }
            mb={0}
          />
        </PageHeader>
        <CouponEditPage />
      </>
    ),
    res: SellerResource.COUPON_MANAGEMENT_WRITE,
  },
  {
    current_menu: 'coupon_modify',
    path: '/coupon/modify',
    params: '',
    component: (
      <>
        <PageHeader>
          <SectionTitle title='쿠폰 수정' mb={0} />
        </PageHeader>
        <CouponEditPage />
      </>
    ),
    res: SellerResource.COUPON_MANAGEMENT_WRITE,
  },
  {
    current_menu: 'promotion_coupon_list',
    path: '/coupon/list',
    params: '',
    component: <CouponListPage />,
    res: SellerResource.COUPON_MANAGEMENT_READ,
  },
  {
    current_menu: 'promotion_coupon_list',
    path: '/coupon/list',
    params: '/:id',
    component: (
      <>
        <PageHeader>
          <SectionTitle title='쿠폰 상세 리포트' mb={0} />
        </PageHeader>
        <CouponReportDetailPage />
      </>
    ),
    res: [SellerResource.COUPON_INSIGHT_READ],
  },
  {
    current_menu: 'promotion_point_add',
    path: '/point/add',
    params: '',
    component: (
      <>
        <PageHeader>
          <SectionTitle
            title='포인트 지급'
            description='포인트 지급은 지그재그(KR) 채널만 지원합니다.'
            textButton={GUIDE_BENEFIT.BENEFIT_INTRO}
            mb={0}
          />
        </PageHeader>
        <PointAddPage />
      </>
    ),
    res: SellerResource.POINT_MANAGEMENT_WRITE,
  },
  {
    current_menu: 'promotion_point_modify',
    path: '/point/modify',
    params: '',
    component: (
      <>
        <PageHeader>
          <SectionTitle title='포인트 수정' mb={0} />
        </PageHeader>
        <PointAddPage is_modify={true} />
      </>
    ),
    res: SellerResource.POINT_MANAGEMENT_WRITE,
  },
  {
    current_menu: 'promotion_point_list',
    path: '/point/list',
    params: '',
    component: <PointListPage />,
    res: SellerResource.POINT_MANAGEMENT_READ,
  },
];
