import { BaseText, HStack, IconCircleInfo, semantic_colors } from '@croquiscom/pds';
import React from 'react';

interface SettingGroupTitleProps {
  disabled?: boolean;
  title: string;
  description?: string;
}

const SettingGroupTitle = ({ title, description, disabled }: SettingGroupTitleProps) => {
  const color = disabled ? semantic_colors.content.disabled : semantic_colors.content.tertiary;
  return (
    <HStack spacing={8} alignment='center'>
      <BaseText kind='Body_12_SemiBold' color={color}>
        {title}
      </BaseText>
      {description && (
        <HStack alignment='center' spacing={4}>
          <IconCircleInfo size={14} color={color} />
          <BaseText kind='Body_12_SemiBold' color={color}>
            {description}
          </BaseText>
        </HStack>
      )}
    </HStack>
  );
};

export default SettingGroupTitle;
