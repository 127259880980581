import {
  HStack,
  Textarea,
  VStack,
  IconButton,
  IconArrowDirectionUp,
  semantic_colors,
  getAutoColorCode,
  BaseText,
  IconCircleInfoFill,
  IconArrowChevronRight,
} from '@croquiscom/pds';
import styled from '@emotion/styled';
import { AnimatePresence, motion } from 'framer-motion';
import React, { useEffect, useRef, useState } from 'react';
import { useZendesk } from '../hooks/useZendesk';
import { ZENDESK_SUGGESTION_KEYWORDS } from './constants';
import { SSE_STATE } from './hooks/useChatBotRequest';
import useMediaQuery from '@/hooks/useMediaQuery';
import { usePbl } from '@/hooks/usePbl';

interface ChatBotFooterProps {
  state: SSE_STATE;
  question?: string;
  sendMessage: (question: string) => void;
  setQuestion: (question: string) => void;
}

export const ChatBotFooter = ({ state, question, sendMessage, setQuestion }: ChatBotFooterProps) => {
  const { is_desktop } = useMediaQuery();
  const { open } = useZendesk();
  const { pbl } = usePbl();
  const [focused, setIsFocused] = useState(false);
  const is_disabled = state === SSE_STATE.LOADING || state === SSE_STATE.RECEIVING;
  const ref = useRef<HTMLTextAreaElement>(null);
  const question_trim = question?.trim() || '';
  const too_short = focused && question_trim.length < 6 && question_trim.length > 0;
  const go_to_zendesk = ZENDESK_SUGGESTION_KEYWORDS.some((keyword) => question_trim.includes(keyword));

  useEffect(() => {
    if (!is_disabled && is_desktop) {
      ref.current?.focus();
    }
  }, [is_disabled]);

  return (
    <VStack spacing={8}>
      <AnimatePresence>
        {(go_to_zendesk || too_short) && (
          <motion.div
            initial={{
              opacity: 0,
              y: -10,
            }}
            animate={{
              opacity: 1,
              y: 0,
            }}
            exit={{
              opacity: 0,
              y: -10,
            }}
            transition={{
              duration: 0.3,
              delay: 0.3,
            }}
          >
            {go_to_zendesk ? (
              <SC.GoToZendesk
                spacing={4}
                alignment='center'
                onClick={() => {
                  pbl({
                    navigation: 'partner_center_chatbot',
                    category: 'click',
                    navigation_sub: {
                      sub: 'chat',
                    },
                    object_id: 'guide_contact',
                  });
                  open();
                }}
              >
                <IconCircleInfoFill color='currentColor' />
                <BaseText kind='Body_12_Medium' color='currentColor'>
                  상담사와의 1:1 채팅 문의는 여기를 눌러주세요.
                </BaseText>
                <HStack ml='auto'>
                  <IconArrowChevronRight color='currentColor' />
                </HStack>
              </SC.GoToZendesk>
            ) : (
              <HStack spacing={4} alignment='center'>
                <IconCircleInfoFill color={semantic_colors.accent.primary} />
                <BaseText kind='Body_12_Medium' color={semantic_colors.accent.primary}>
                  대화하듯이 문장으로 질문할수록 답변의 정확도가 높아져요.
                </BaseText>
              </HStack>
            )}
          </motion.div>
        )}
      </AnimatePresence>
      <SC.Container spacing={8} alignment='center' focused={focused} disabled={is_disabled}>
        <HStack width='100%' alignment='center'>
          <VStack width='100%'>
            <SC.TextArea
              ref={ref}
              placeholder='예시: 판매자 등급 서비스는 무엇인가요?'
              name='question'
              autoComplete='off'
              disabled={is_disabled}
              value={question}
              autoSize={{ minRows: 1, maxRows: 5 }}
              onFocus={() => setIsFocused(true)}
              onBlur={() => setIsFocused(false)}
              onChange={(e) => {
                setQuestion(e.target.value);
              }}
              onKeyDown={(e) => {
                if (e.key === 'Enter' && !e.shiftKey) {
                  e.preventDefault();
                }
              }}
              onKeyUp={(e) => {
                if (e.key === 'Enter' && question && !e.shiftKey && question.trim() !== '') {
                  sendMessage(question);
                }
              }}
            />
          </VStack>
        </HStack>
        <HStack pt={6} pb={6} height='auto' alignment='bottom'>
          <SC.Button
            shape='circle'
            icon={<IconArrowDirectionUp />}
            size='xsmall'
            disabled={is_disabled}
            onClick={() => question && sendMessage(question)}
          />
        </HStack>
      </SC.Container>
    </VStack>
  );
};

const SC = {
  Container: styled(HStack)<{ focused: boolean; disabled: boolean }>`
    padding: 0 6px 0 0;
    overflow: hidden;
    border-radius: 19px;
    border: 1px solid
      ${({ focused, disabled }) =>
        disabled
          ? semantic_colors.border.disabled
          : focused
            ? semantic_colors.border.active
            : semantic_colors.border.default};
    ${({ disabled }) => (disabled ? `background-color: ${semantic_colors.background.disabled};` : '')}
    align-items: stretch;
  `,
  GoToZendesk: styled(HStack)`
    color: ${semantic_colors.state.warning};
    cursor: pointer;

    :hover {
      color: ${getAutoColorCode(semantic_colors.state.warning).hover};
      background-color: ${getAutoColorCode(semantic_colors.background.surface).hover};
    }
    :active {
      color: ${getAutoColorCode(semantic_colors.state.warning).pressed};
      background-color: ${getAutoColorCode(semantic_colors.background.surface).pressed};
    }
  `,
  TextArea: styled(Textarea)`
    padding: 6px 0 6px 14px;
    border-radius: 0;
    border: none;
    outline: none;
    :focus,
    :hover {
      outline: none;
      border: none;
    }
  `,
  Button: styled(IconButton)`
    background-color: ${semantic_colors.accent.primary};
    color: ${semantic_colors.content.on_color};

    &:hover {
      background-color: ${getAutoColorCode(semantic_colors.accent.primary).hover};
      color: ${getAutoColorCode(semantic_colors.content.on_color).hover};
    }
    &:active {
      background-color: ${getAutoColorCode(semantic_colors.accent.primary).pressed};
      color: ${getAutoColorCode(semantic_colors.content.on_color).pressed};
    }

    &:disabled {
      background-color: ${semantic_colors.accent.primary};
      color: ${semantic_colors.content.on_color};
      opacity: 0.2;
    }
  `,
};
