import { Button, IconArrowChevronRight, VStack } from '@croquiscom/pds';
import React from 'react';
import { TAB_ITEM } from '../constants';
import ProductPerformanceModal from '../modal/ProductPerformanceModal';
import { usePbl } from '@/hooks/usePbl';
import { useQueryParams } from '@/hooks/useQueryParams';
import { ShopPriceOptimizationTargetProductBasicInfo } from 'api';
import { useModalState } from 'rui/pastel/unstable/modal';

interface ProductPerformanceButtonProps {
  basic_info: Pick<
    ShopPriceOptimizationTargetProductBasicInfo,
    'image_url' | 'product_name' | 'product_id' | 'fulfillment_type'
  >;
}

const ProductPerformanceButton: React.FC<ProductPerformanceButtonProps> = ({ basic_info }) => {
  const [{ isOpen, onClose, onOpen }] = useModalState(false);
  const { pbl } = usePbl();
  const query = useQueryParams();
  const tab = query.get('tab') as TAB_ITEM;
  return (
    <VStack alignment='leading' mt={4}>
      <Button
        endIcon={<IconArrowChevronRight />}
        kind='secondary'
        size='xsmall'
        onClick={() => {
          pbl({
            navigation: 'price_optimization',
            category: 'click',
            navigation_sub: {
              page: tab === TAB_ITEM.RESULT ? 'result' : 'active',
            },
            object_section: 'product_performance',
          });
          onOpen();
        }}
      >
        성과 보기
      </Button>
      <ProductPerformanceModal product_id={basic_info.product_id} opened={isOpen} onClose={onClose} />
    </VStack>
  );
};

export default ProductPerformanceButton;
