import { IconCoupon } from '@croquiscom/pds';
import { t } from 'i18next';
import React from 'react';
import {
  checkRes,
  isAllowedMenuByCountry,
  isAllowedMenuBySiteId,
  isNotAllowedByShopDepartment,
} from '../../utils/menu_list';
import { MenuEntry } from '../types';
import { CreateMenuTreeOptions } from './types';
import { SiteId } from '@/constants/site';
import { ShopDepartment, ShopType } from '@/types/info';
import { CountryCode, SellerResource } from 'api';

export function createPromotionMenuTree({ info }: CreateMenuTreeOptions): MenuEntry[] {
  const is_zpay = info.type === ShopType.ZPAY;
  const is_shop_promotion_allowed =
    !info.is_parent &&
    is_zpay &&
    isAllowedMenuBySiteId(info, [SiteId.지그재그, SiteId.포스티, SiteId.마이스토어]) &&
    isNotAllowedByShopDepartment(info, [ShopDepartment.CELEB]);
  const is_promotion_allowed = !info.is_parent && is_zpay && isNotAllowedByShopDepartment(info, [ShopDepartment.CELEB]);
  return [
    {
      id: 'promotion',
      label: t('main_frame::혜택 및 프로모션'),
      is_new: info.is_price_optimization_target,
      icon: <IconCoupon size={16} color='currentColor' />,
      items: [
        {
          id: 'promotion_coupon',
          label: t('main_frame::혜택 관리'),
          items: [
            {
              id: 'promotion_coupon_list',
              label: t('main_frame::쿠폰'),
              href: '/coupon/list',
              is_allowed:
                is_promotion_allowed && checkRes([SellerResource.COUPON_MANAGEMENT_READ], info.allowed_resource_list),
            },
            {
              id: 'promotion_point_list',
              label: t('main_frame::포인트'),
              href: '/point/list',
              is_allowed:
                is_promotion_allowed &&
                checkRes([SellerResource.POINT_MANAGEMENT_READ], info.allowed_resource_list) &&
                isAllowedMenuBySiteId(info, [SiteId.지그재그, SiteId.포스티, SiteId.도매, SiteId.마이스토어]),
            },
            {
              id: 'promotion_coupon_my_pick_list',
              label: t('main_frame::마이픽 쿠폰'),
              href: '/coupon/my_pick/list',
              is_allowed:
                is_promotion_allowed &&
                checkRes([SellerResource.COUPON_MANAGEMENT_READ], info.allowed_resource_list) &&
                isAllowedMenuBySiteId(info, [SiteId.지그재그]) &&
                isAllowedMenuByCountry(info, [CountryCode.KR]) &&
                isNotAllowedByShopDepartment(info, [ShopDepartment.KIDS]),
            },
          ],
        },
        {
          id: 'promotion_event',
          label: t('main_frame::프로모션 관리'),
          items: [
            {
              id: 'promotion_management',
              label: t('main_frame::기획전 신청'),
              href: '/promotion/shop',
              is_allowed: is_shop_promotion_allowed,
            },
            {
              id: 'promotion_price_history',
              label: t('main_frame::할인 판매가'),
              href: '/promotion/price/history',
              is_allowed:
                isAllowedMenuBySiteId(info, [SiteId.지그재그, SiteId.포스티, SiteId.패션_바이_카카오]) &&
                isAllowedMenuByCountry(info, [CountryCode.KR]),
            },

            {
              id: 'free_shipping_management',
              label: t('main_frame::무료배송 예약'),
              href: '/promotion/free_shipping/management',
              is_allowed: is_shop_promotion_allowed,
            },
            {
              id: 'catalog_promotion_history',
              label: t('main_frame::1+N 판매 프로모션'),
              href: '/promotion/bogo/history',
              is_allowed:
                is_zpay &&
                isAllowedMenuBySiteId(info, [SiteId.지그재그]) &&
                isAllowedMenuByCountry(info, [CountryCode.KR]) &&
                isNotAllowedByShopDepartment(info, [ShopDepartment.CELEB]),
            },
            {
              id: 'exhibition_management',
              label: t('main_frame::스토어픽'),
              href: '/promotion/exhibition/management',
              is_allowed:
                is_shop_promotion_allowed &&
                checkRes([SellerResource.PROMOTION_EXHIBITION_MANAGEMENT], info.allowed_resource_list),
            },
            {
              id: 'price_optimization',
              label: '가격 최적화',
              href: '/promotion/price_optimization',
              is_allowed: info.is_price_optimization_target,
              is_new: true,
              is_beta: true,
            },
          ],
        },
      ],
    },
  ];
}
