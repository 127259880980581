import { BaseText, HStack, IconArrowDirectionRight, ImagePartnerPlusTextWh, semantic_colors } from '@croquiscom/pds';
import styled from '@emotion/styled';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { usePbl } from '@/hooks/usePbl';
import { useShopPrefix } from '@/hooks/useShopPrefix';
import { PartnerPlusNavUrl } from 'rui/components/URL';
interface PartnerPlusBannerProps {
  disabled?: boolean;
}

const PartnerPlusBanner = ({ disabled }: PartnerPlusBannerProps) => {
  const history = useHistory();
  const shop_prefix = useShopPrefix();
  const { pbl } = usePbl();

  return (
    <Container
      alignment='center'
      onClick={() => {
        pbl({
          navigation: 'notification_settings',
          object_id: 'subscribe_request_partnerplus',
          object_type: 'button',
          category: 'click',
        });
        history.push(`${shop_prefix}${PartnerPlusNavUrl.INTRO}`);
      }}
      disabled={disabled}
    >
      <ImagePartnerPlusTextWh size={62} />
      <BaseText kind='Body_12_Medium' color={semantic_colors.content.on_color}>
        구독하고 주문 알림 받기
      </BaseText>
      <HStack ml='auto'>
        <IconArrowDirectionRight size={14} color={semantic_colors.content.on_color} />
      </HStack>
    </Container>
  );
};

export default PartnerPlusBanner;

const Container = styled(HStack)<{ disabled?: boolean }>`
  padding: 6px 12px;
  gap: 4px;
  border-radius: 6px;
  background: linear-gradient(90deg, #aa5bf3 0%, #725be7 100%);
  ${({ disabled }) => disabled && `pointer-events: none; opacity: 0.3;`}
`;
