import { useEffect, useRef } from 'react';
import { useLocation } from 'react-router'; // LegacyCompat 변환 X
import useMediaQuery from '@/hooks/useMediaQuery';

import {
  CATALOG_ITEM_LIST_URL,
  PARTNER_PLUS_INTRO_URL,
  PARTNER_PLUS_REAL_TIME_URL,
  PRODUCT_REVIEW_LIST_URL,
  STATS_URL,
} from 'rui/components/URL';

export function ScrollToTop(): null {
  const { pathname } = useLocation();
  const { is_mobile } = useMediaQuery();
  const isMountedRef = useRef<boolean>(false);

  useEffect(() => {
    // 첫 마운트 이후에만 실행
    if (isMountedRef.current) {
      const ad_domain_list = is_mobile ? [] : ['/ad/zigzag'];
      const shouldScrollToTop = ![
        ...ad_domain_list,
        '/ad/3.0/reports',
        '/attention/main',
        '/attention/detail',
        '/advertisement',
        '/fbk_ads/ad_set',
        '/ad_display/management_two',
        '/wallet',
        '/shipping_penalty',
        '/shop_score',
        CATALOG_ITEM_LIST_URL,
        PRODUCT_REVIEW_LIST_URL,
        STATS_URL,
        PARTNER_PLUS_REAL_TIME_URL,
        PARTNER_PLUS_INTRO_URL,
      ].some((path) => pathname.includes(path));

      if (shouldScrollToTop) {
        window.scrollTo({ top: 0 });
      }
    }
    isMountedRef.current = true;
  }, [pathname, is_mobile]);

  return null;
}
