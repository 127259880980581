import { BaseText, IconCircleInfo, PageHeader, SectionTitle, TextButton, VStack, colors } from '@croquiscom/pds';
import styled from '@emotion/styled';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ZIGZIN_GUIDE_OPTIONS } from '../constants';
import { HelpConstantLink } from '@/components/common/HelpConstantLink';

const StockPageHeader = () => {
  const { t } = useTranslation('zigzin');

  return (
    <PageHeader border={false}>
      <SectionTitle
        title={t('재고 관리')}
        textButton={
          <HelpConstantLink options={ZIGZIN_GUIDE_OPTIONS} linkKey='운영 가이드'>
            <TextButton kind='link' size='large' startIcon={<IconCircleInfo />} type='button'>
              가이드 보기
            </TextButton>
          </HelpConstantLink>
        }
        description={
          <VStack>
            <StyledPageHeaderDescriptionList>
              <li>
                <BaseText kind='Body_13_Regular' color={colors.gray700}>
                  직진배송 물류센터에 보관 중인 재고 현황을 확인할 수 있습니다.
                </BaseText>
              </li>
              <li>
                <BaseText kind='Body_13_Regular' color={colors.gray700}>
                  진열안함 상태의 상품은 재고와 상관없이 서비스에 노출되지 않습니다.
                </BaseText>
              </li>
              <li>
                <BaseText kind='Body_13_Regular' color={colors.gray700}>
                  미입고품절 상태의 상품은 입고 완료 시점에 “판매대기”에서 “진열”로 상태가 변경됩니다.
                </BaseText>
              </li>
              <li>
                <BaseText kind='Body_13_Regular' color={colors.gray700}>
                  장기재고 안내 후 1달이 경과하였음에도 불구하고 장기재고 상태를 유지할 경우 상품이 반출될 수 있습니다.
                </BaseText>
              </li>
              <li>
                <BaseText kind='Body_13_Regular' color={colors.gray700}>
                  상품의 진열 여부와 관계없이 장기재고 로직이 적용됩니다.
                </BaseText>
              </li>
            </StyledPageHeaderDescriptionList>
          </VStack>
        }
        mb={0}
      />
    </PageHeader>
  );
};

export default StockPageHeader;

const StyledPageHeaderDescriptionList = styled.ul`
  > li::before {
    content: '-';
    padding-right: 4px;
  }
`;
