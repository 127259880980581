import { useState } from 'react';
import { ChatBotMenuOption } from '../constants';
import { ChatBotQnA } from '../types';
import { chat_bot_session } from '../utils/session';
import { usePbl } from '@/hooks/usePbl';

export const useChatBotState = () => {
  const { pbl } = usePbl();
  const [modal_state, setModalState] = useState({
    preset_opened: true,
    guide_opened: false,
    chatbot_opened: false,
    feedback_opened: false,
  });
  const [thread_data, setThreadData] = useState<ChatBotQnA[]>([]);
  const [current_question, setCurrentQuestion] = useState({
    request_question: '',
    input_question: '',
  });

  const onDone = ({ answer, message_id, is_no_answer, question }) => {
    const qna: ChatBotQnA = {
      message_id,
      is_no_answer,
      question: {
        text: question || '',
        timestamp: Date.now(),
      },
      answer: {
        text: answer || '',
        timestamp: Date.now(),
      },
    };
    pbl({
      navigation: 'partner_center_chatbot',
      navigation_sub: {
        sub: 'chat',
      },
      category: 'impression',
      object_id: 'answer',
      extra: JSON.stringify({
        is_answer: !is_no_answer,
      }),
    });
    setThreadData((prev) => [...prev, qna]);
    setCurrentQuestion((prev) => ({
      ...prev,
      input_question: '',
    }));
  };

  const startNewThread = () => {
    setModalState((prev) => ({
      ...prev,
      preset_opened: true,
    }));
    setCurrentQuestion({
      request_question: '',
      input_question: '',
    });
    setThreadData([]);
    chat_bot_session.refresh();
  };

  const onSelectMenu = (type: ChatBotMenuOption) => {
    pbl({
      navigation: 'partner_center_chatbot',
      navigation_sub: {
        sub: 'chat',
      },
      object_id: 'more',
      category: 'click',
      extra: JSON.stringify({
        type,
      }),
    });
    if (type === ChatBotMenuOption.CHATBOT_GUIDE) {
      setModalState((prev) => ({
        ...prev,
        guide_opened: true,
      }));
    } else if (type === ChatBotMenuOption.CHATBOT_FAQ) {
      setModalState((prev) => ({
        ...prev,
        preset_opened: true,
      }));
    }
  };

  const openFaq = () => {
    setModalState((prev) => ({
      ...prev,
      guide_opened: true,
    }));
  };

  return {
    modal_state,
    thread_data,
    current_question,
    setModalState,
    setCurrentQuestion,
    onDone,
    openFaq,
    onSelectMenu,
    startNewThread,
  };
};
