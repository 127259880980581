import { Alert, BaseText } from '@croquiscom/pds';

export const stopPriceAvailableSucess = ({ onClose }) => {
  return Alert({
    kind: 'success',
    title: '추천가 적용 중지 완료',
    onClose,
  });
};

export const stopPriceAvailableFail = ({ message }) => {
  return Alert({
    kind: 'error',
    title: '추천가 적용 중지 실패',
    text: message || '죄송합니다. 추천가 적용 중지에 실패했습니다. 잠시 후 다시 시도해 주세요.',
  });
};

export const stopPriceActiveSucess = ({ onClose }) => {
  return Alert({
    kind: 'success',
    title: '추천가 적용 중지 완료',
    text: '수 분 내에 추천가 적용 및 노출 확대가 중지됩니다. 잠시 후 새로고침 해주세요.',
    onClose,
  });
};

export const stopPriceActiveFail = ({ message }) => {
  return Alert({
    kind: 'error',
    title: '추천가 적용 중지 실패',
    text: message || '죄송합니다. 추천가 적용 중지에 실패했습니다. 잠시 후 다시 시도해 주세요.',
  });
};

export const blockedProduct = ({ basic_info }) => {
  return Alert({
    kind: 'error',
    title: '판매 불가 사유',
    text: (
      <BaseText>
        {basic_info.sales_status !== 'ON_SALE' && (
          <>
            · 판매 상태가 ‘판매중’이 아님
            <br />
          </>
        )}
        {basic_info.display_status !== 'VISIBLE' && (
          <>
            · 진열 상태가 ‘진열’이 아님
            <br />
          </>
        )}
        {basic_info.product_status !== 'VALID' && (
          <>
            · 유효하지 않은 상품
            <br />
          </>
        )}
        {basic_info.penalty_status === 'PENALTY' && (
          <>
            · 페널티가 적용됨
            <br />
          </>
        )}
        {basic_info.suspend_status === 'BLOCK' && <>· 연동 관련 속성 변경 필요</>}
      </BaseText>
    ),
  });
};

export const stopOptionPriceSucess = ({ onClose }) => {
  return Alert({
    kind: 'success',
    title: '옵션 할인 판매가 적용 중지 완료',
    onClose,
  });
};

export const stopOptionPriceFail = ({ message }) => {
  return Alert({
    kind: 'error',
    title: '옵션 할인 판매가 적용 중지 실패',
    text: message || '잠시 후 다시 시도해주세요.',
  });
};

export const resetPricePartialFail = ({ onClose }) => {
  return Alert({
    kind: 'error',
    title: '새로운 추천가 확인 실패',
    text: '죄송합니다. 아직 기존에 적용했던 추천가 중지 작업이 진행 중입니다. 잠시 후 새로고침하면 새로운 추천가를 확인할 수 있습니다.',
    onClose,
  });
};

export const resetPriceFail = ({ message }) => {
  return Alert({
    kind: 'error',
    title: '새로운 추천가 확인 실패',
    text: message || '죄송합니다. 잠시 후 다시 시도해 주세요.',
  });
};

export const applyPriceFail = ({ message }) => {
  return Alert({
    kind: 'error',
    title: '추천가 적용 실패',
    text: message || '죄송합니다. 추천가 적용에 실패했습니다. 다시 추천가를 적용해 주세요.',
  });
};
export const applyPricePartialFail = () => {
  return Alert({
    kind: 'error',
    title: '추천가 적용 실패',
    text: '죄송합니다. 추천가 적용에 실패했습니다. 진행 중이던 작업을 취소하고 있으니, 잠시 후 다시 추천가를 적용해 주세요.',
  });
};

export const applyPriceSuccess = () => {
  return Alert({
    kind: 'success',
    title: '추천가 적용 완료',
    text: '해당 상품은 수 초 내에 선택한 ‘추천가’로 적용되며, ‘노출 확대 중’ 목록에서 확인할 수 있습니다. 잠시 후 새로고침 해주세요.',
  });
};

export const applyPriceSuccessForTheme = () => {
  return Alert({
    kind: 'success',
    title: '추천가 적용 완료',
    text: '해당 상품은 수 초 내에 선택한 ‘추천가’로 적용되며, ‘노출 확대 중’ 목록에서 확인할 수 있습니다.',
  });
};

export const stopBulkPriceSuccess = ({ total_count }) => {
  return Alert({
    kind: 'success',
    title: `추천가 적용 중지 성공 (${total_count}건)`,
    text: '수 분 내에 추천가 적용 및 노출 확대가 중지됩니다. 잠시 후 새로고침 해주세요.',
  });
};
export const stopBulkPriceFail = ({ message }) => {
  return Alert({
    kind: 'error',
    title: '추천가 적용 중지 실패',
    text: message || '죄송합니다. 추천가 적용 중지에 실패했습니다. 잠시 후 다시 시도해 주세요.',
  });
};

export const stopBulkPricePartialFail = ({ total_count, failed_count, onClose }) => {
  return Alert({
    kind: 'success',
    title: `추천가 적용 중지 완료 (${total_count}건)`,
    text: (
      <>
        적용 중지 성공 상품의 경우, 수 분 내에 기존 ‘할인 판매가’로 변경되며 ‘노출 확대 가능 상품’ 목록에서 확인할 수
        있습니다. 잠시 후 새로고침 해주세요.
        <BaseText>
          · 성공: {total_count - failed_count}건<br />· 실패: {failed_count}건
        </BaseText>
      </>
    ),
    onClose,
  });
};
